import React, { useState } from 'react';
import Icons from '../../../Constants/Icon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
const Customdrop = ({ languages, handleItemselect, displayText }) => {
    const [isOpendrop, setIsOpendrop] = useState(false);
    const handleMouseLeave = () => {
        setIsOpendrop(false);
    };
    const navigate = useNavigate();
    const handleItemClick = (itmes) => {
        navigate(itmes.route);
    }
    const { t } = useTranslation();
    return (
        <div className="custom-select" style={{ width: "100%" }} onMouseLeave={() => handleMouseLeave()}>
            <div className="selected" onMouseOver={() => setIsOpendrop(!isOpendrop)} >
                <div className='pr-3'>
                    {displayText.map((itmes) => (
                        <div className='md_p' to={itmes.route} onClick={() => handleItemClick(itmes)}  >{t(itmes.displayText)}</div>
                    ))}
                </div>
                <div className={`dropdown-icon ${isOpendrop ? 'open' : ''}`}> <img src={Icons.arrowdown} className='' /></div>
            </div>
            <ul className={`-mt-1 lg:mt-0 options ${isOpendrop ? 'open' : ''}`}  >
                {languages.map(language => (
                    <Link to={language.Route}>
                        <li key={language.code} onClick={handleItemselect}>
                            {t(language.name)}
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
};

export default Customdrop;
